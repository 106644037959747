import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import EmunSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/emun-selector/1.0.0/index.vue';
import consumer_web_company_returnHZApplyStatus from '@/lib/data-service/haolv-default/consumer_web_company_returnHZApplyStatus'
import consumer_web_company_getHZCardLevel from '@/lib/data-service/haolv-default/consumer_web_company_getHZCardLevel'
import consumer_web_company_getHZCertificateType from '@/lib/data-service/haolv-default/consumer_web_company_getHZCertificateType'
import consumer_web_company_hzCompanyOnlineRegis from '@/lib/data-service/haolv-default/consumer_web_company_hzCompanyOnlineRegis'
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
export default {
    data() {
        const validateRregisteredCapital = (rule, value, callback) => {
            if (value < 0) {
                callback(new Error('注册基金必须大于0'))
            } else {
                callback()
            }
        }
        return {
            loading: false,
            applyStatus: '', // 华住审核状态 1：审核中 2：审核不通过
            form: {
                businessLicenseNum: '',
                cardLevel: 'D',
                companyAddress: '',
                companyName: '',
                contactEmail: '',
                contactFullName: '',
                contactIdNoType: 'C01',
                contactIdNo: '',
                contactMobile: '',
                contactSex: '',
                enterpriseEmail: '',
                idNoAttach: '', // 附件
                legalPerson: '',
                licenseAttach: '',
                registeredCapital: 0,
                taxNumber: '',
            },
            isRead: false,
            rules: {
                taxNumber: [
                    { required: true, message: '请输入企业税号', trigger: 'blur' },
                ],
                legalPerson: [
                    { required: true, message: '请输入法人姓名', trigger: 'blur' },
                ],
                registeredCapital: [
                    { required: true, message: '注册资金不能为空'},
                    { type: 'number', message: '注册资金必须为数字值'},
                    { validator: validateRregisteredCapital, trigger: 'blur' }
                ],
                cardLevel: [
                    { required: true, message: '开卡级别不能为空'},
                ],
                contactFullName: [
                    { required: true, message: '联系人姓名不能为空'},
                ],
                contactMobile: [
                    { required: true, message: '联系人手机号不能为空'},
                ],
                contactEmail: [
                    { required: true, message: '联系人邮箱不能为空'},
                ],

                companyName: [
                    { required: true, message: '公司名称不能为空'},
                ],
                companyAddress: [
                    { required: true, message: '公司地址不能为空'},
                ],
                businessLicenseNum: [
                    { required: true, message: '营业执照号不能为空'},
                ],
                licenseAttach: [
                    { required: true, message: '营业执照不能为空'},
                ]
            },
            show: false,
            dialogVisible: false,
            fileMaxSize: 10,
            ossClient: new OssClient(),
            imageUrl: [],
        }
    },
    components: {EmunSelector},
    created() {
    },
    mounted() {
        console.log(this.$refs.cardLevelEmunSelector)
        this.$refs.cardLevelEmunSelector.init({
            type: `100`,
            request_url: null,
            entity_define: {
                property_name_value: 'value',
                property_name_text: 'text',
            },
            get_data_source() {
                const data = {
                    entity_list: [],
                };
                return consumer_web_company_getHZCardLevel().then((res) => {
                    const list = res.datas.resultList;
                    data.entity_list = list
                    return data;
                })
            },
            option_all: {
                is_show: false,
            },
        })
        this.$refs.contactIdNoTypeEmunSelector.init({
            type: `100`,
            request_url: null,
            entity_define: {
                property_name_value: 'value',
                property_name_text: 'text',
            },
            get_data_source() {
                const data = {
                    entity_list: [],
                };
                return consumer_web_company_getHZCertificateType().then((res) => {
                    const list = res.datas.resultList;
                    data.entity_list = list
                    return data;
                })
            },
            option_all: {
                is_show: false,
            },
        })
    },
    activated() {
        this.getReturnHZApplyStatus()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        resetForm() {
            this.form = {
                businessLicenseNum: '',
                cardLevel: 'D',
                companyAddress: '',
                companyName: '',
                contactEmail: '',
                contactFullName: '',
                contactIdNoType: 'C01',
                contactIdNo: '',
                contactMobile: '',
                contactSex: '',
                enterpriseEmail: '',
                idNoAttach: '', // 附件
                legalPerson: '',
                licenseAttach: '',
                registeredCapital: 0,
                taxNumber: '',
            }
            this.imageUrl = []
        },
        getReturnHZApplyStatus() {
            this.loading = true
            consumer_web_company_returnHZApplyStatus().then(res => {
                this.applyStatus = res.datas.applyStatus
                let form = res.datas.result
                this.resetForm()
                let oriForm = this.form
                if (form) {
                    form.registeredCapital = parseInt(form.registeredCapital)
                    for (let i in form) {
                        if (form[i]) {
                            oriForm[i] = form[i]
                        }
                    }
                    this.form = oriForm
                    if (this.form.idNoAttach) {
                        let idNoAttachArr = this.form.idNoAttach.split('/')
                        let imageUrlParams = {
                            name: idNoAttachArr[idNoAttachArr.length - 1],
                            url: this.form.idNoAttach
                        }
                        this.imageUrl = [imageUrlParams]
                    }
                    console.log('las', this.form)
                }

            }).finally(() => {
                this.loading = false
            })
        },
        inputRegisteredCapital(val) {
            if (val >= 300000000) {
                this.form.cardLevel = 'B'
            } else if (val < 300000000 && val >= 500000) {
                this.form.cardLevel = 'C'
            } else {
                this.form.cardLevel = 'D'
            }
        },
        toggleShow() {
            this.show = !this.show
        },
        showDialog() {
            this.dialogVisible = true
        },
        changeFile(file) {
            let fileParams = {
                name: file.name,
                url: ''
            }
            const size = file.size / 1024 / 1024;
            if (size > this.fileMaxSize) {
                return this.$message.error(`文件大小不能超过${this.fileMaxSize}M`);
            }
            this.ossClient.multipartUpload({
                file: file.raw,
            }).then(res => {
                fileParams.url = res.url
                this.$message.success("文件上传成功");
                this.imageUrl = [fileParams];
            });
            this.$refs.imgUpload.clearFiles()
        },
        downloadUrl(val) {
            window.open(val.url, "_blank");
        },
        deleteFile(index) {
            this.imageUrl.splice(index, 1);
        },
        back() {
            this.$router.go(-1)
        },
        async submit() {
            if (!this.isRead) {
                this.$message({
                    type: "warning",
                    message: '请先阅读并同意《华住企业会员计划与服务条款》'
                })
                return
            }
            let checkRes = await this.checkForm()
            console.log(checkRes)
            if (!checkRes) {
                return
            }
            this.loading = true
            let form = JSON.parse(JSON.stringify(this.form))
            form.registeredCapital = form.registeredCapital.toString()
            if (this.imageUrl.length > 0) {
                form.idNoAttach = this.imageUrl[0].url
            }
            let [err, res] = await awaitWrap(consumer_web_company_hzCompanyOnlineRegis(form))
            if (err) {
                this.loading = false
                return
            }
            this.loading = false
            if (res.result.code === 200) {
                // 提示3秒，返回首页
                this.$message({
                    message: '提交成功，审核结果将于2个工作日内线下告知，请耐心等待！',
                    type: 'success'
                });
                setTimeout(() => {
                    this.back()
                }, 3000)
            } else {
                this.$message({
                    type: "warning",
                    message: res.result.message
                })
            }
        },
        checkForm() {
            return new Promise((resolve, reject) => {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
            })
        },
    }
}